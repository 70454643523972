@import '/src/App.scss';

.filter_wrapper{
		padding: 10px 0;
}

.filter{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(150px, auto));
	gap: 1em;
	justify-content: space-around;
	width: 90%;
	margin: 0 auto;
	padding: 20px 0;
}

.filter_item{
	border-right: 1px solid #7b7b7bd4;
	margin: 15px 0;
	padding: 0 10px;
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 10;
	display: flex;
	justify-content: center;

	width: 100%;

}

.filter_input{
	background: none;
	border: none;
	outline: none;
	padding: 5px 0;
	margin-top: 5px;
	border-bottom: 1px solid silver;
	width: 70%;
	color: whitesmoke;
}

.filter_select{
	margin-top: 5px;
}

.filter_item:last-child{
	border-right: none;
}

.filter_name{
	color: #7B7B7B;
	font-size: 12px;
	letter-spacing: 0.5px;
}

.ant-select-single.ant-select-show-arrow  .ant-select-open{
	color: $orange;
}
.ant-select .ant-select-arrow{
	background: #7B7B7B;
	padding: 10px;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ant-select{
	display: flex;
	align-items: center;
	justify-content: left;
	color: whitesmoke;

}


.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
 padding: 0;
}

.ant-select .ant-select-selection-item{
  color: whitesmoke;
  text-align: left;
  letter-spacing: 0.5px;
  font-family: Montserrat;
  margin-left: 0;
  padding-left: 0;
  font-size: 13px;
}

.ant-select-dropdown{
	background: $gray;
}
	

.ant-select-dropdown .ant-select-item .ant-select-item-option-content{
	color: whitesmoke;
}

.ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled){
	background-color: rgba(105, 104, 104, 0.667);
	
}

.filter_btn{
	border: 2px solid $orange;
	padding: 8px 30px;
	background: none;
	color: whitesmoke;
	font-size: 12px;
	letter-spacing: 0.5px;
	margin-top: 15px;
	transition: all 0.3s ease-in;
	position: relative;
	z-index: 10;
	display: flex;
	align-self: center;
}

.filter_btn:hover{
	background: $orange;
	color: whitesmoke;
	scale: 1.03;
}

@media screen and (min-width: 1400px) {
	.filter{
			grid-template-columns: repeat(auto-fit, minmax(180px, auto));
	}

	.filter_item{
		margin: 0 auto;
		justify-content: left;
	}
}

@media screen and (max-width: 1090px ) and (min-width: 905px) {
	.filter_item:nth-child(5){
	border-right: none;

}
}

@media screen and (max-width: 904px ) and (min-width: 720px) {
	.filter_item:nth-child(4){
		border-right: none;
	}
}
@media screen and (max-width: 719px ) and (min-width: 636px) {
	.filter_item:nth-child(3){
		border-right: none;
	}
}

@media screen and (max-width: 635px ) and (min-width: 536px) {
	.filter_item:nth-child(3){
		border-right: none;
	}
}
@media screen and (max-width: 535px ) and (min-width: 352px) {
	.filter_item:nth-child(2){
		border-right: none;
	}
	.filter_item:nth-child(4){
		border-right: none;
	}
}
@media screen and (max-width: 351px) {
	.filter_item{
		border-right: none;
		margin: 10px 0;
		width: 200px;
		background-color: #343333;
		padding: 7px 15px;
	}
	.filter{
				gap:  0 0.9em;
	}

	.filter_item:last-child{
		background: none;
	}

	.filter_btn{
		display: flex;
		align-self: center;
		margin: 0 auto;
	}
}