@import '/src/App.scss';

.footer{
	display: flex;
	padding: 40px 0px 0 0px ;
	width: 100%;
	margin: 0 auto;
	flex-direction: column;
	background: $gray;
	color: rgba(222, 222, 222, 1);
}

.footer_logo{
	display: flex;
	justify-content: center;
	margin: 20px auto;
	flex-direction: column;
	width: 80px;
	
	
}

.footer_logo_img{
	width: 70px;
	height: 50px;
}

.logo_img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.footer_item{
	display: flex;
}

.footer_item_text{
   display: flex;
   width: 100px !important;
   margin: 10px;
   align-items: center;
   font-size: 14px;
}


.footer_item_detail{
   text-align: left;
   width: 200px;
    margin: 10px;
	font-size: 14px;
	line-height: 1.5;
  
}

.footer_link{
	display: flex;
	flex-direction: column;
	grid-area: l;
	width: 100%;
}

.footer_link_item{
	margin: 10px;
	text-align: left;
	transition: all 0.2s ease-in;
}

.footer_link_item:hover{
   color: $orange;
}

.divider3{
	border-bottom: 0.5px solid $orange;
	width: 40%;
}

.social_networks{
	display: flex;
	font-size: 16px;
	color:$orange;
	
}
.social_networks div{
	margin: 10px 10px;
	transition: all 0.5s ease-in;
}


.social_networks div:hover{
  scale: 1.3;
}

.map{
	grid-area: m;
	height: 300px;
}

.iframe_map{
	border: none;
	width: 100%;
	height: 300px;
}

.footer_content{
	display: grid;
	grid-template-columns: 1fr 2fr;
	gap: 3em;
	justify-content: center;
	grid-template-areas: 'c m';
	padding: 50px;
}


@media screen and (max-width: 1000px) and (min-width: 600px){
	.footer_content{
		display: grid;
		grid-template-columns: 1fr 1fr;
		justify-content: center;
		grid-template-areas: 'm m m' 'c c l';
		gap: 30px;
	}
}

@media screen and (max-width: 599px){
	.footer{
		width: 85%;
		justify-content: center;
	}

	.footer_logo{
		width: 90%;
	}

	.footer_logo_img{
		margin: 0 auto;
	}

	.footer_content{
		display: grid;
		grid-template-columns: 1fr;	
		justify-content: center;
		grid-template-areas: 'm m m' 'c c c' 'l l l';
		margin: 0 auto;
	}
	.footer_item{
		align-items: flex-start;
		margin: 15px auto;
	}

	.footer_item_text{
		align-items: flex-start;
		margin-right: 10px !important;
		margin-top: 0;
	}

	.footer_item_detail{
    margin: 0px;

}

.divider{
	margin-bottom: 30px;
}

	
}

.footer_block{
	grid-area: c;
}

.h3_contacts{
	padding-bottom: 5px ;
	margin: 5px;
	border-bottom: 1px solid $orange;
	width: 80px;

}

.empty_blocks{
	display: flex;
	justify-content: center;
	margin-top: 10px;
}

.block{
	width: 7px;
	height: 7px;
	background: #D9D9D9;
	margin: 5px 10px;
}

.bottom_footer{
	font-size: 12px;
	font-weight: 100;
	letter-spacing: 0.5px;
	text-align: center;
	color: rgba(172, 172, 172, 1);
	background: rgba(28, 28, 28, 0.756);
	padding: 15px 0;

}

.network_icon{
	color: $orange;
	transition: all 0.3s ease-in;
}

.network_icon:hover{
	scale: 1.3;
}

.social_networks_icons{
	margin: 20px 10px;
}