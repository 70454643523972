@import '/src/App.scss';
.menu{
	height: 100px;
	width: 100%;
	font-size: 16px;
	color:rgba(255, 255, 255, 1);
	font-weight: 400;
}

.menu_content{
	display: flex;
	justify-content: space-between;
}

.logo_block{
	height: 40px !important;
	display: block;
}

.logo_block img{
	width: 60px;

}

.menu_inner{
	width: 90%;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 1fr 4fr;
	gap: 1em;
	align-items: center !important;
	height: 100%;


}


.menu_item, .menu_car_tracker{
	transition: all 0.3s ease-in;
	letter-spacing: 1.5px;
}

.menu_item:hover, .menu_car_tracker:hover{
	color: $orange !important;
}

.menu_logo{
	width: 50px;
	height: 40px;
}

