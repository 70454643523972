@import '/src/App.scss';

.catalogue_page{
	width: 100%;
	margin: 0 auto;
	background: $gray;
}

.catalogue_banner{
	width: 100%;
}

.banner_img{
	width: 100%;
}

.catalogue_sidebar{
	display: flex;
	flex-direction: column;
	text-align: left;
	padding: 20px 0;
}


@media only screen and (max-width: 1200px ){
	.catalogue_main_content{
      grid-template-columns: 1fr !important;
	  gap: 3em;
	}

	.catalogue_main{
		grid-template-columns: repeat(auto-fit, minmax(250px, auto)) !important;
		justify-content: center;
	}

	.catalogue_sidebar aside {
		width: 90% !important;
		max-width: 90%  !important;
		margin: 0 auto;
		border: none !important;
		flex: 1 !important;
	}

	.catalogue_sidebar aside .ant-layout-sider-children ul{
		border: none !important;
		display: flex;
		flex-wrap: wrap;
	}

	.catalogue_sidebar aside .ant-layout-sider-children ul li {
		background: #eee;
		width: 130px;	
	}
}

@media only screen and (min-width: 1500px ){
	.catalogue_main{
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(300px, auto));
		gap: 3em !important;
	}

	.category_filtr{
      font-size: 24px;	 
	}

	.ant-menu-title-content{
		font-size: 18px;
	}

	.catalogue_main_content{
	width: 80%;
	margin: 0 auto;
}
	
}


.catalogue_main_content{
	width: 80%;
	margin: 0 auto;
}

.catalogue_main{
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, auto));
	gap: 3em;
	justify-content: center;
}

.category_filtr{
	text-align: left;
	font-weight: bold;
	margin-left: 27px;
	margin-bottom: 20px;
}

