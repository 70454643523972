@import '/src/App.scss';


.slider{
   position: relative;
   	height: 800px;
	width: 100%;
}

.slider_img_block{
	position: absolute;
	top: 0;
	left: 0;
	height: 800px;
	width: 100%;
	z-index: 0;
	filter: brightness(1.05);
	background: rgba(0, 0, 0, 0.98);
}

.slider_img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.slider_menu{
	position: relative;
	z-index: 1;

}

.slider_text_block{
	position: absolute;
	right: 40px;
	bottom: 40px;
	top: 280px;
	z-index: 2;
	width: 600px;
	height: 300px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(6px);
	
}

.slider_text_block{
	color: whitesmoke;
	padding: 70px;
}

.slider_horizontal{
	border-left: 6px solid $orange;
	padding-left: 40px;
	font-size: 3.8em;
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	letter-spacing: 1px;
}
.slider_bold_header{
	font-weight: bold;
}

.slider_normal_header{
	font-weight: 300;
	letter-spacing: 3px;
	font-size: 48px;
	
}

.slider_description{
	padding: 40px;
	display: flex;
	flex-wrap: wrap;
	letter-spacing: 1px;
	font-weight: 300;
	line-height: 1.8;
}

.slider_btn{
	margin-left: 40px;
	padding: 10px 30px;
	border: 2px solid $orange;
	width: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	letter-spacing: 0.8px;
	transition: all 0.2s ease-in;
}

.slider_btn:hover{
	background: $orange;
	color: whitesmoke;
	scale: 1.05;
}

@media screen and (max-width: 800px) and (min-width: 500px){
   
	.slider{
		height: 700px;
	}

	.slider_text_block{
		width: 100%;
		margin: 0 auto;
		height: 85%;
		left: 0;
		top: 100px;
		padding: 0;

	}

	.slider_horizontal{
		padding: 30px 20px;
		margin: 50px 50px 0px 50px;
	font-size: 2.5em;
	width: 80%;

}

.slider_description {
	width: 80%;
	margin: 0px 40px;
}

.slider_btn{
	margin: 0px 80px;
}
}

@media screen and (max-width: 500px) {
	.slider{
		height: 620px;
	}
	.slider_text_block{
		width: 99%;
		margin: 0 auto;
		height: 82%;
		left: 0;
		top: 100px;
		padding: 0;
		padding-bottom: 10px;
	}

	.slider_horizontal{
		padding: 30px 20px;
		margin: 50px 30px 0px 30px;
		font-size: 2em;
		width: 80%;
}

.slider_description {
	width: 75%;
	margin: 0px auto;

	padding: 30px 5px;

}

.slider_btn{
	margin-left: 60px;

}
}

.player-wrapper {
  width: auto; 
  height: auto; 

}

.react-player {
  padding-top: 56.25%;
  position: 
  relative; 
}


.react-player > div {
  position: absolute; 
}

.modal_logo_img{
	width: 55px;
	height: 40px;
}

.ant-modal-content{
	text-align: center;
}
