/* .home{
	position: relative;
} */

.whatsapp{
	position: fixed;
	bottom: 50px;
	right: 40px;
	height: 50px;
	width: 50px;
	z-index: 100;
	border-radius: 50%;
	-webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.whatsapp_icon{
	width: 100%;
	height: 100%;
	object-fit: cover;
	
transition: all 0.3s ease-in;
}

.whatsapp_icon:hover{
	scale: 1.1;
}