@import '/src/App.scss';

.mobile_header_inner{
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;

}

.mobile_menu{
    display: flex;
    flex-direction: column;
    align-items: flex-end;

}

.mobile_menu div:first-child{
    width: 35px;
    margin-bottom: 7px;
    border-bottom: 2px solid $orange;

}

.mobile_menu div:last-child{
    width: 20px;
    margin-bottom: 7px;
    border-bottom: 2px solid $orange;
}

.mobile_header{
    width: 90%;
    margin: 0 auto;
}

.mobile_menu_modal .ant-modal-content{

    width: 100%;
    top: 0 !important;
    left: 0 !important;
    color: whitesmoke;
    background: $gray;
}

.mobile_header{
    display: flex;
    flex-direction: column;
}

.mobile_menu_item{
 
    margin: 10px 0;
    width: 100%;
    display: flex;
    transform: all 0.3s ease-in;

}

.mobile_menu_item:hover{
  color: $orange !important;
}

.slash{
    margin-right: 10px;
    color: $orange;
}

.mobile_modal .modal{
    height: 300px !important;
}